import { render, staticRenderFns } from "./dynamicPage.vue?vue&type=template&id=c31ce352&scoped=true&"
import script from "./dynamicPage.vue?vue&type=script&lang=js&"
export * from "./dynamicPage.vue?vue&type=script&lang=js&"
import style0 from "./dynamicPage.vue?vue&type=style&index=0&id=c31ce352&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c31ce352",
  null
  
)

export default component.exports