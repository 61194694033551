(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ELEMENT"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define("HJDynamicFormPluginBasic", ["ELEMENT", "vue"], factory);
	else if(typeof exports === 'object')
		exports["HJDynamicFormPluginBasic"] = factory(require("ELEMENT"), require("vue"));
	else
		root["HJDynamicFormPluginBasic"] = factory(root["ELEMENT"], root["vue"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 