import dynamicPage from "./pages/dynamicPage.vue";
import dynamicFormView from "./components/dynamicFormView/src/dynamicFormView";
import conditionSelectView from "./components/conditionSelectView/src/conditionSelectView";
import generalConst from "./tool/generalConst";
import {Button, Input,Select,InputNumber,Tooltip,Option,Dialog,RadioGroup,Radio,DatePicker} from 'element-ui';
import './css/form-style.scss'
import verify from "./tool/verify";
import util from "./tool/util";

const vueComponent = {
    install: (app, options) => {
        app.use(Button);
        app.use(Input);
        app.use(Select);
        app.use(InputNumber);
        app.use(Tooltip);
        app.use(Option);
        app.use(Dialog);
        app.use(RadioGroup);
        app.use(Radio);
        app.use(DatePicker);
        app.use(dynamicFormView);
        app.use(conditionSelectView);


        app.prototype.$verify = verify;
        app.prototype.$tool = util;
        app.prototype.$const = generalConst;
        app.component('newBasic', dynamicPage)
    }
}
export default vueComponent;
